import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class WithdrawMoney extends React.Component {

    constructor() {
        super();
        this.state = {
            amount: "",
            displayPopup:"none",
            upi:"",
            mcc:"",
            min_amount:0,
            history:[],
            withdraw:"",
            withdraw_rules:"",
        }
    }

    componentDidMount() {

        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/withdraw_reqs.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        history:response.data.data,
                        withdraw_rules:response.data.withdraw_rules,
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }

    


    render() {

        let handleSubmit = async () =>{

            
            let url = "https://api.whatsapp.com/send?phone="+localStorage.getItem("mobile")+"&text=Username%20-%20"+localStorage.getItem("name")+"%0AMobile%20-%20"+localStorage.getItem("mobile")+"%0AWithdraw%20Points%20-%20%20"+this.state.amount+"Rs"

            // Open the URL in a new tab
            window.open(url, "_blank");

            this.setState({
                showLoading:"none",
            })

        };



        let apiCall = () =>{

            if(parseInt(localStorage.getItem("wallet")) < parseInt(this.state.amount)){
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Insufficient Balance"
                })
            } else {
               // handleSubmit();

               

                var form = new FormData();
                form.append("session", localStorage.getItem("token"));
                form.append("mobile", localStorage.getItem("mobile"));

                form.append("amount", this.state.amount);

                axios
                    .post(process.env.REACT_APP_URL+`/api2/withdraw_request.php`,form)
                    .then((response) => {
                        if (response.data.nosession){
                            Swal.fire({
                                toast: true,
                                title: 'Oops...',
                                text: "Session Expired"
                            }).then((e)=>{
                                localStorage.clear()
                                window.location.href = "/login";
                            })
                        }
                        if(response.data.success === "0"){
                            Swal.fire({
                                toast: true,
                                title: 'Oops...',
                                text: response.data.msg,
                            })
                        } else {
                            Swal.fire({
                                toast: true,
                                title: 'Withdraw Request',
                                text: response.data.msg,
                            })
                        }
                    })

            }

          
        }

        
        let hideDialog = ()=>{
            this.setState({
                dialogShow:"none",
            })
        }


        return (<>
                <div className="RootView">
                    <div className="RootWidth" style={{background:"#eee"}}>

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Withdraw Funds</span>
                                </div>


                            </div>



                            <div style={{padding:"15px"}} className="withdraw-form">


                                <div className="withdrawWallet">
                                    <img src="../images/wallet_wit.png" />
                                    <div>
                                        <span style={{fontSize:"21px"}}>{localStorage.getItem("wallet")}</span>
                                        <span style={{fontSize:"14px"}}>Current Balance</span>
                                    </div>
                                </div>

                                <p className="withdrawp">
                                    Call or Whatsapp for withdraw<br/>Related Queries<br/><br/>Monday To Sunday ( Timing 00:00 To 23:59 )
                                </p>

                                <div className="inputBlock" style={{background:"white", border:"solid 1px #000"}}>
                                    <img src="../images/login_icon.png" />
                                    <input
                                        name="amount"
                                        type="number"
                                        value={this.state.amount}
                                        onChange={(e)=>{this.setState({amount:e.target.value})}}
                                        placeholder="Enter Points"
                                    />
                                </div>

                             

                               

                                <button className="button withdrawButton" onClick={apiCall}>
                                    Request Withdraw
                                </button>

                             


                            </div>

                        </div>


                    </div>
                </div>



                <div className="review-bets-dialog" style={{display:this.state.dialogShow}}>
      <div className="dialog-content homedlg">
      
      <div className="dlgtoolbar">
            <span>IMPORTANT INFORMATION</span>
            <span style={{fontWeight:"900"}} onClick={()=>{hideDialog()}}>X</span>
        </div>


        <div className="main-dlg-content">
        <p className="desc">
        <div dangerouslySetInnerHTML={{__html: this.state.withdraw_rules}} className='editor'></div>

                </p>
        </div>
        
      </div>
    </div>



            </>
        );
    }

}

export default WithdrawMoney;



