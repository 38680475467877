import { useState, useEffect  } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
import axios from "axios";

const Home = () => {

    const navigate = useNavigate();
    const [bids, setbids] = useState([]);
    const [suggestions, setsuggestions] = useState([]);

    const [openSession, setopenSession] = useState("activeButton");
    const [closeSession, setcloseSession] = useState("inactiveButton");
    const [display, setdisplay] = useState("");
    const [session, setSession] = useState("OPEN"); // Initialize with a default value
    const [gameType, setgameType] = useState("SP"); // Initialize with a default value

    
    const [totalAmount, settotalAmount] = useState("");
    
    const [totalBids, settotalBids] = useState(0);

        
    const [showLoading, setshowLoading] = useState("none");
    const [dialogShow, setdialogShow] = useState("none");
    const [dialogShowSuccess, setdialogShowSuccess] = useState("none");

    let showDialog = ()=>{
        setdialogShow("flex")
    }
    
    let hideDialog = ()=>{
        setdialogShow("none")
    }


    
      

    let timing = ""

    let data = useLocation();

    if (data.state == null){
        window.location.href = "/";
    }

    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;
    let game_name = data.state[0].game;
    if (data.state[0].timing !== undefined) {
        timing = data.state[0].timing
    }



   let numbers = [
    "127", "136", "145", "190", "235", "280", "370", "389", "460", "479", "569", "578", "128", "137", "146", 
    "236", "245", "290", "380", "470", "489", "560", "579", "678", "129", "138", "147", "156", "237", "246", 
    "345", "390", "480", "570", "589", "679", "120", "139", "148", "157", "238", "247", "256", "346", "490", 
    "580", "670", "689", "130", "149", "158", "167", "239", "248", "257", "347", "356", "590", "680", "789", 
    "140", "159", "168", "230", "249", "258", "267", "348", "357", "456", "690", "780", "123", "150", "169", 
    "178", "240", "259", "268", "349", "358", "367", "457", "790", "124", "160", "278", "179", "250", "269", 
    "340", "359", "368", "458", "467", "890", "125", "134", "170", "189", "260", "279", "350", "369", "468", 
    "378", "459", "567","126", "135","180","234","270","289","360","379","450","469","478","568"
];


    let getSuggest = (val)=>{
        setbid(val)
        if (val === ""){
            setsuggestions([])
            return
        }
        // let tempSuggestion = []
        // for (let a = 0; a < numbers.length; a++){
        //     if (numbers[a].substring(0, val.length) === val){
        //         tempSuggestion.push(numbers[a])
        //     }
        // }
        // setsuggestions(tempSuggestion)
    }

    let changeSession = (e)=>{
        console.log(e);
        if (e === "OPEN" && is_open_status === "1"){
            setSession("OPEN")
        } else if (e === "CLOSE"){
            setSession("CLOSE")
        }
    }

    useEffect(() => {
        // Check the condition and update the session state accordingly
        if(game_name !== "jodi"){
            if (is_open_status === "0") {
            setSession("CLOSE");
            } else {
            setSession("OPEN");
            }
        } else {
            setSession("");
        }
      }, [is_open_status]); // The effect will re-run whenever is_open_status changes



    const getTotalAmount = (bids) => {
        let totalAmount = 0;
        bids.forEach(bid => {
          totalAmount += parseInt(bid.amount);
        });
        return totalAmount;
      };

    
      
      let addBid = () => {
        if (parseInt(amount) >= localStorage.getItem('min_bet')) {

            if (gameType === "ODD") {
                setbids(prevBids => {
                    const updatedBids = [...prevBids, {amount: amount, number: "1", session: session}];
                    const totalAmount = getTotalAmount(updatedBids);
                    settotalAmount(totalAmount);
                    settotalBids(updatedBids.length);
                    return updatedBids;
                  });
                  setbids(prevBids => {
                      const updatedBids = [...prevBids, {amount: amount, number: "3", session: session}];
                      const totalAmount = getTotalAmount(updatedBids);
                      settotalAmount(totalAmount);
                      settotalBids(updatedBids.length);
                      return updatedBids;
                    });
                    setbids(prevBids => {
                        const updatedBids = [...prevBids, {amount: amount, number: "5", session: session}];
                        const totalAmount = getTotalAmount(updatedBids);
                        settotalAmount(totalAmount);
                        settotalBids(updatedBids.length);
                        return updatedBids;
                      });
                      setbids(prevBids => {
                          const updatedBids = [...prevBids, {amount: amount, number: "7", session: session}];
                          const totalAmount = getTotalAmount(updatedBids);
                          settotalAmount(totalAmount);
                          settotalBids(updatedBids.length);
                          return updatedBids;
                        });
                        setbids(prevBids => {
                            const updatedBids = [...prevBids, {amount: amount, number: "9", session: session}];
                            const totalAmount = getTotalAmount(updatedBids);
                            settotalAmount(totalAmount);
                            settotalBids(updatedBids.length);
                            return updatedBids;
                          });

            } else if (gameType === "EVEN") {
                setbids(prevBids => {
                    const updatedBids = [...prevBids, {amount: amount, number: "2", session: session}];
                    const totalAmount = getTotalAmount(updatedBids);
                    settotalAmount(totalAmount);
                    settotalBids(updatedBids.length);
                    return updatedBids;
                  });
                  setbids(prevBids => {
                      const updatedBids = [...prevBids, {amount: amount, number: "4", session: session}];
                      const totalAmount = getTotalAmount(updatedBids);
                      settotalAmount(totalAmount);
                      settotalBids(updatedBids.length);
                      return updatedBids;
                    });
                    setbids(prevBids => {
                        const updatedBids = [...prevBids, {amount: amount, number: "6", session: session}];
                        const totalAmount = getTotalAmount(updatedBids);
                        settotalAmount(totalAmount);
                        settotalBids(updatedBids.length);
                        return updatedBids;
                      });
                      setbids(prevBids => {
                          const updatedBids = [...prevBids, {amount: amount, number: "8", session: session}];
                          const totalAmount = getTotalAmount(updatedBids);
                          settotalAmount(totalAmount);
                          settotalBids(updatedBids.length);
                          return updatedBids;
                        });
                        setbids(prevBids => {
                            const updatedBids = [...prevBids, {amount: amount, number: "0", session: session}];
                            const totalAmount = getTotalAmount(updatedBids);
                            settotalAmount(totalAmount);
                            settotalBids(updatedBids.length);
                            return updatedBids;
                          });
            }
         
        } else if (parseInt(amount) < 10) {
          Swal.fire({
            toast: true,
            title: 'Oops...',
            text: "Bid Amount must be above "+localStorage.getItem("min_bet")+" INR"
          });
        } else if (!numbers.includes(parseInt(bid)) && !numbers.includes(bid)) {
          Swal.fire({
            toast: true,
            title: 'Oops...',
            text: "Enter valid bid number"
          });
        }
      };
      
      let removePeople = (e) => {
        setbids(prevBids => {
          const updatedBids = prevBids.filter(person => person !== e);
          const totalAmount = getTotalAmount(updatedBids);
          settotalAmount(totalAmount);
          settotalBids(updatedBids.length);
          return updatedBids;
        });
      };

    const [amount, setamount] = useState("");
    const [bid, setbid] = useState("");


    let setbidss = (bal)=>{
        setbid(bal)
        setsuggestions([])
    }

    let loginSubmit = () =>{

        setshowLoading("block");
        let numberString,amountString,typesString

        let numberArray = []
        let amountArray = []
        let typesArray = []

        bids.map((result) => {
            numberArray.push(result.number)
            amountArray.push(result.amount)
            typesArray.push(result.session)
        })

        numberString = numberArray.join(",")
        amountString = amountArray.join(",")
        typesString = typesArray.join(",")

        var form = new FormData();

        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        form.append("bazar", marketName);
        form.append("number", numberString);
        form.append("amount", amountString);
        form.append("types", typesString);
        form.append("game", game_name);
        if (timing !== "") {
            form.append("timing", timing);
        }
        axios
            .post(process.env.REACT_APP_URL+`/api2/bet.php`,form)
            .then((response) => {
                if (response.data.nosession){
                    setshowLoading("none");
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: "Session Expired"
                    }).then((e)=>{
                        localStorage.clear()
                        window.location.href = "/login";
                    })
                }
                if(response.data.success === "0"){
                    setshowLoading("none");
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.msg
                    })
                } else {
                    setshowLoading("none");
                    setdialogShow("none")
                    setdialogShowSuccess("flex")
                }
            })
    }


    return (<>
            <div className="RootView">
                <div className="RootWidth">

                <div style={{display:showLoading}}>
                        <div class="loader-parent">
                        <div class="loader-block">
                        <div class="loader"></div> 
                        </div>
                        </div>
                    </div>

                    <div className="loginDiv">

                        <div className="toolbar">
                            <div>
                                <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                <span style={{ textTransform: "uppercase"}}>{marketName}, ODD EVEN</span>
                            </div>
                            <Link to={"/wallet"}>
                                <div>
                                    <img className="wallet-img" src="../images/wallet_figma.png"/>
                                    <span className="wallet-text">{localStorage.getItem("wallet")}</span>
                                </div>
                            </Link>
                        </div>



                     

                        <div className="main-game-screen">

                        { game_name !== "jodi" ?
                        <div className="game-play-box" style={{marginBottom: "10px"}}>
                                <span>
                                    Select Game Type :
                                </span>
                                <select onChange={(e) => changeSession(e.target.value)} >
                                { is_open_status !== "0" ?
                                    <option value="OPEN">OPEN</option>  : ""}
                                    <option value="CLOSE">CLOSE</option>
                                </select>
                            </div> : ""}

                            <div className="radioGroup">
                                <div>
                                    <input
                                    type="radio"
                                    id="sp"
                                    name="type"
                                    value="ODD"
                                    checked={gameType === "ODD"}
                                    onChange={(e) => setgameType(e.target.value)}
                                    />
                                    <label htmlFor="sp">ODD</label>
                                </div>
                                <div>
                                    <input
                                    type="radio"
                                    id="dp"
                                    name="type"
                                    value="EVEN"
                                    checked={gameType === "EVEN"}
                                    onChange={(e) => setgameType(e.target.value)}
                                    />
                                    <label htmlFor="dp">EVEN</label>
                                </div>
                              
                                </div>
                          


                            <div className="game-play-box">
                                <span>
                                    Enter Points :
                                </span>
                                <div>
                                    <input
                                        className="edittext"
                                        name="mobile"
                                        type="number"
                                        style={{marginTop:"10px"}}
                                        value={amount}
                                        onChange={(e)=>{setamount(e.target.value)}}
                                        placeholder="Enter Points"
                                    />

                                </div>
                            </div>

                            <div className="game-play-add">
                                <button className="button" onClick={addBid}>
                                    ADD BID
                                </button>
                            </div>

                            <div className="bet-list bottom-border">
                                    <span>
                                        Digit
                                    </span>
                                    <span>
                                       Points
                                    </span>
                                    <span>
                                        Game type
                                    </span>
                                </div>

                            { bids.map((result) => { return (
                                <div className="bet-list bet-shadow">
                                    <span>
                                        {result.number}
                                    </span>
                                    <span>
                                       {result.amount}
                                    </span>
                                    <span>
                                        {result.session} <img style={{marginLeft:"10px"}} onClick={()=>{removePeople(result)}} src="../images/ic_delete.png" />
                                    </span>
                                </div>
                            )})}


                        </div>
                    </div>

                </div>
                { totalBids !== 0 ?
                <div className="game-play-bottom">

                    <div className="game-play-bottom-first-div">
                        <div className="list-div">
                            <span>Bid</span>
                            <span>{totalBids}</span>
                        </div>
                        
                        <div className="list-div">
                            <span>Total</span>
                            <span>{totalAmount}</span>
                        </div>
                    </div>
                    
                    <div>
                    <button className="button" onClick={showDialog}>
                                Submit
                            </button>
                    </div>
                </div>
                 : ""}


<div className="review-bets-dialog" style={{display:dialogShowSuccess}}>
      <div className="dialog-content">
      
        <div className="bet-details" style={{flexDirection:"column", alignItems:"center"}}>

            <img src="../images/success_green.png" style={{height:"75px", marginBottom:"14px"}} />
            <span style={{color:"green"}}>Your Bids Placed Successfully</span>
         
         
        </div>
       
        <div className="dialog-actions">
          <button className="submit-button" onClick={()=>{window.history.go(-1)}}>OK</button>
        </div>
      </div>
    </div>

<div className="review-bets-dialog" style={{display:dialogShow}}>
      <div className="dialog-content">
        <div className="dialog-header">
          <h2>Review Bets</h2>
        </div>
        <div className="bets-table">
          <div className="table-header">
            <div>Digit</div>
            <div>Points</div>
            <div>Type</div>
          </div>
          { bids.map((result) => { return (
            <div className="bet-list bet-shadow">
                <span>
                    {result.number}
                </span>
                <span>
                    {result.amount}
                </span>
                <span>
                    {result.session}
                </span>
            </div>
        )})}
        </div>
        <div className="bet-details">
          <div>
            <span>Total Bids</span>
            <strong>{totalBids}</strong>
          </div>
          <div>
            <span>Total Bid Points</span>
            <strong>{totalAmount}</strong>
          </div>
        </div>
        <div className="balance-details">
          <div>
            <span>Point Balance Before Game Play</span>
            <strong>{localStorage.getItem("wallet")}</strong>
          </div>
          <div>
            <span>Point Balance After Game Play</span>
            <strong>{localStorage.getItem("wallet") - totalAmount}</strong>
          </div>
        </div>
        <div className="note">
          <p>Note: Bid Once Played Can Not Be Cancelled</p>
        </div>
        <div className="dialog-actions">
          <button className="cancel-button" onClick={hideDialog}>Cancel</button>
          <button className="submit-button" onClick={loginSubmit}>Submit</button>
        </div>
      </div>
    </div>


            </div>

        </>
    );
};

export default Home;
