import { useState } from "react";
import {Link, useLocation} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';

const Home = (props) => {

    const [amount, setamount] = useState("");
    let data = useLocation();

    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;
    // function back(){
    //     let navigate = useNavigate();
    //     navigate(-1)
    // }


    return (<>
            <div className="RootView" style={{background:"#eee"}}>
                <div className="RootWidth">

                    <div className="loginDiv">


                        <div className="toolbar">
                            <div>
                                <img src="../images/back.png" onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                <span>Select Game</span>
                            </div>


                        </div>

                        <div>
                             
                             
                        <div className="games">
                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'single'}]}>
                                <div className="maingameblock">
                                    <div className="imgBlock">
                                       <img src="../images/ic_singledigits.png" />
                                    </div>
                                    <span>Haruf</span>
                                </div>
                                </Link>

                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                                    <div className="maingameblock">
                                        <div className="imgBlock">
                                        <img src="../images/jodi.png" />
                                        </div>
                                        <span>Jodi</span>
                                    </div>
                                    </Link>
                              
                            </div>


                             
                            
                                    <div className="games">

                                    <Link to={"/crossing"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                                    <div className="maingameblock">
                                        <div className="imgBlock">
                                        <img src="../images/group_jodi.png" />
                                        </div>
                                        <span>Crossing</span>
                                    </div>
                                    </Link>

                                  


                                    </div>




                        </div>

                    </div>


                </div>
            </div>


        </>
    );
};

export default Home;
