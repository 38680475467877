import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";

import React from 'react';
import Home from "./AppComponents/Home";
import Starline from "./AppComponents/Starline";
import Gali from "./AppComponents/Gali";
import MainBets from "./AppComponents/MainBets";
import MainBetsMain from "./AppComponents/MainBetsMain";
import Login from "./AppComponents/Login";
import Signup from "./AppComponents/Signup";
import Splash from "./AppComponents/Splash";
import PaymentSuccess from "./AppComponents/PaymentSuccess";
import PaymentFailed from "./AppComponents/PaymentFailed";
import DepositMoney from "./AppComponents/DepositMoney";
import Games from "./AppComponents/Games";
import GameRates from "./AppComponents/GameRates";
import Wallet from "./AppComponents/Wallet";
import SPDPTP from "./AppComponents/SPDPTP";
import Motor from "./AppComponents/Motor";
import HalfSangam from "./AppComponents/HalfSangam";
import OddEven from "./AppComponents/OddEven";
import RedBracket from "./AppComponents/RedBracket";
import GaliGames from "./AppComponents/GaliGames";
import StarlineGames from "./AppComponents/StarlineGames";
import Profile from "./AppComponents/Profile";
import GameHistory from "./AppComponents/GameHistory";
import WithdrawMoney from "./AppComponents/WithdrawMoney";
import TransferPoints from "./AppComponents/TransferPoints";
import HowToPlay from "./AppComponents/HowToPlay";
import Chart from "./AppComponents/Chart";
import Forgot from "./AppComponents/Forgot";
import ResetPassword from "./AppComponents/ResetPassword";
import RequireAuth from "./AppComponents/RequireAuth";
import GameNotifications from "./AppComponents/GameNotifications";
import Winning from "./AppComponents/Winning";
import Charts from "./AppComponents/Charts";
import GameHistorySelection from "./AppComponents/GameHistorySelection";
import WinningHistorySelection from "./AppComponents/WinningHistorySelection";
import SingleBulk from "./AppComponents/SingleBulk";
import JodiBulk from "./AppComponents/JodiBulk";
import SinglePanaBulk from "./AppComponents/SinglePanaBulk";
import PanelGroup from "./AppComponents/PanelGroup";
import ChoicePana from "./AppComponents/ChoicePana";
import TwoDigitPanel from "./AppComponents/TwoDigitPanel";
import GroupJodi from "./AppComponents/GroupJodi";
import DigitBasedJodi from "./AppComponents/DigitBasedJodi";
import HalfSangamA from "./AppComponents/HalfSangamA";
import HalfSangamB from "./AppComponents/HalfSangamB";
import FullSangam from "./AppComponents/FullSangam";
import Passbook from "./AppComponents/Passbook";
import Funds from "./AppComponents/Funds";
import MyBids from "./AppComponents/MyBids";
import BankDetails from "./AppComponents/BankDetails";
import DepositHistory from "./AppComponents/DepositHistory";
import WithdrawHistory from "./AppComponents/WithdrawHistory";
import Videos from "./AppComponents/Videos";
import Crossing from "./AppComponents/Crossing";

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();

  return (
      promiseInProgress &&
      <div className="backdraw">
        <img src="/src/images/loading.svg" />
      </div>
  );
}

const App = ()=>{

    document.title = process.env.REACT_APP_NAME

  return (
      <>
        <LoadingIndicator/>
        <Router>
          <Routes>
              <Route path="/" element={<Splash />}  />
              <Route path="/paymentSuccess" element={<PaymentSuccess />}  />
              <Route path="/paymentFailed" element={<PaymentFailed />}  />

              <Route path="/login" element={<Login />}  />
              <Route path="/signup" element={<Signup />}  />
              <Route path="/forgot" element={<Forgot />}  />
              <Route path="/resetPassword" element={<ResetPassword />}  />

              <Route path="/deposit" element={<RequireAuth><DepositMoney /></RequireAuth>}  />
              <Route path="/wallet" element={<RequireAuth><Wallet /></RequireAuth>}  />
              <Route path="/withdraw" element={<RequireAuth><WithdrawMoney /></RequireAuth>}  />
              <Route path="/transfer" element={<RequireAuth><TransferPoints /></RequireAuth>}  />

              <Route path="/home" element={<RequireAuth><Home /></RequireAuth>}  />
              <Route path="/games" element={<RequireAuth><Games /></RequireAuth>}  />

              <Route path="/charts" element={<RequireAuth><Charts /></RequireAuth>}  />
              <Route path="/gali" element={<RequireAuth><Gali /></RequireAuth>}  />

              {/* <Route path="/order" element={<RequireAuth><MainBets /></RequireAuth>}  /> */}
              <Route path="/order" element={<RequireAuth><MainBetsMain /></RequireAuth>}  />
              <Route path="/mainBulk" element={<RequireAuth><SingleBulk /></RequireAuth>}  />
              <Route path="/jodiBulk" element={<RequireAuth><JodiBulk /></RequireAuth>}  />
              <Route path="/spdptp" element={<RequireAuth><SPDPTP /></RequireAuth>}  />
              <Route path="/motor" element={<RequireAuth><Motor /></RequireAuth>}  />
              <Route path="/halfsangam" element={<RequireAuth><HalfSangam /></RequireAuth>}  />
              <Route path="/oddeven" element={<RequireAuth><OddEven /></RequireAuth>}  />
              <Route path="/redbracket" element={<RequireAuth><RedBracket /></RequireAuth>}  />
              <Route path="/singlePanaBulk" element={<RequireAuth><SinglePanaBulk /></RequireAuth>}  />
              <Route path="/panelGroup" element={<RequireAuth><PanelGroup /></RequireAuth>}  />
              <Route path="/choicePana" element={<RequireAuth><ChoicePana /></RequireAuth>}  />
              <Route path="/twoDigitPanel" element={<RequireAuth><TwoDigitPanel /></RequireAuth>}  />
              <Route path="/groupJodi" element={<RequireAuth><GroupJodi /></RequireAuth>}  />
              <Route path="/digitBasedJodi" element={<RequireAuth><DigitBasedJodi /></RequireAuth>}  />
              <Route path="/halfSangamA" element={<RequireAuth><HalfSangamA /></RequireAuth>}  />
              <Route path="/halfSangamB" element={<RequireAuth><HalfSangamB /></RequireAuth>}  />
              <Route path="/fullSangam" element={<RequireAuth><FullSangam /></RequireAuth>}  />
              <Route path="/crossing" element={<RequireAuth><Crossing /></RequireAuth>}  />
              


              <Route path="/gameRates" element={<RequireAuth><GameRates /></RequireAuth>}  />
              <Route path="/passbook" element={<RequireAuth><Passbook /></RequireAuth>}  />
              <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>}  />
              <Route path="/gameHistory" element={<RequireAuth><GameHistory /></RequireAuth>}  />
              <Route path="/gameHistorySelection" element={<RequireAuth><GameHistorySelection /></RequireAuth>}  />
              <Route path="/winningHistorySelection" element={<RequireAuth><WinningHistorySelection /></RequireAuth>}  />
              <Route path="/gameRules" element={<RequireAuth><HowToPlay /></RequireAuth>}  />
              <Route path="/chart" element={<RequireAuth><Chart /></RequireAuth>}  />
              <Route path="/notifications" element={<RequireAuth><GameNotifications /></RequireAuth>}  />
              <Route path="/winnings" element={<RequireAuth><Winning /></RequireAuth>}  />
              <Route path="/videos" element={<RequireAuth><Videos /></RequireAuth>}  />

              <Route path="/gali" element={<RequireAuth><Gali /></RequireAuth>}  />
              <Route path="/galiGames" element={<RequireAuth><GaliGames /></RequireAuth>}  />


              <Route path="/starline" element={<RequireAuth><Starline /></RequireAuth>}  />
              <Route path="/starlineGames" element={<RequireAuth><StarlineGames /></RequireAuth>}  />
              <Route path="/funds" element={<RequireAuth><Funds /></RequireAuth>}  />
              <Route path="/myBids" element={<RequireAuth><MyBids /></RequireAuth>}  />
              <Route path="/bankDetails" element={<RequireAuth><BankDetails /></RequireAuth>}  />
              <Route path="/withdrawHistory" element={<RequireAuth><WithdrawHistory /></RequireAuth>}  />
              <Route path="/depositHistory" element={<RequireAuth><DepositHistory /></RequireAuth>}  />
          </Routes>
        </Router>
      </>
  );
}

export default App;
